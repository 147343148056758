<template>
  <section>
    <Survey :survey="survey" v-if="jsonSurveyDesign != null" />
    <b-row style="margin-bottom: 20px" v-else>
      <b-col cols="12">
        <h1>Chargement en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// survey
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-vue-ui";
import { Model } from "survey-core";
import "survey-core/i18n/french";
import "survey-core/i18n/english";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BMedia,
  BAvatar,
  BTable,
  BCardHeader,
  BCardTitle,

  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import collectModule from "./collectModule";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,

    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // survey
    Survey,
  },
  data() {
    // ---------------------------------------------------
    const jsonSurveyDesign = this.$router.currentRoute.params.formData;
    // transform data
    console.log("----------start");
    console.log(jsonSurveyDesign.name);
    console.log(jsonSurveyDesign.description);
    console.log(JSON.parse(jsonSurveyDesign.form_design.form_design));
    console.log("----------end");

    const json = {
      title: jsonSurveyDesign.name,
      description: jsonSurveyDesign.description,
      completedHtml: "<h3>Merci pour ce rapport</h3>",
      pages: JSON.parse(jsonSurveyDesign.form_design.form_design),
      showQuestionNumbers: "off",
      completeText: "Envoyer / Enregistrer",
      widthMode: "responsive",
      showCompletedPage: false,
    };
    // ---------------------------------------------------
    // detect language 
    var userLang = navigator.language || navigator.userLanguage;  

    const survey = new Model(json);
    if(userLang=="fr-FR"){
      survey.locale = "fr";
    }else{
      survey.locale = "en";
    }
    survey.onComplete.add(this.addUpdateItemAction);

    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      reference_selected: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      modulesOptions: [],
      referencesOptions: [],
      showFormCreatorGui: false,
      survey,
      jsonSurveyDesign,
    };
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    // console
    if (router.currentRoute.name == "form_data-add-new") {
      this.operationTitle = "Nouveau ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == "form_data-view") {
      this.operationTitle = "Détails du ";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == "form_data-edit") {
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    reinitForm() {
      //
      this.$props.formData.name = "";
      this.$props.formData.sku_form = "";
      this.$props.formData.sku_department = "";
      this.$props.formData.sku_module = "";
      this.$props.formData.sku_reference = "";
      this.$props.formData.form_type = "";
      // remove all occurences
      // initialise 1 element
      // this.$props.formData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.formData.name == "") {
        this.errors.push("Compléter le nom du form svp.");
      }
      if (this.errors.length > 0) {
        // console.log(this.errors);
        return false;
      } else {
        return true;
      }
    },
    async getElementsByModule(element_selected) {
      // dynamic get online
      this.reference_selected = element_selected;

      //
      (this.referencesOptions = []),
        await myApi
          .get(element_selected)
          .then((response) => {
            // default action
            var data = response.data.data;
            var $sku_element = `sku_` + element_selected;

            var i = 0;
            for (i = 0; i < data.length; i++) {
              this.referencesOptions.push({
                label: data[i].name,
                value: data[i].$sku_element,
              });
            }
          })
          .catch((error) => {
            //
            console.log(error);
          });
    },
    async addUpdateItemAction(sender) {
      const defaultActionText = this.actionName;

      let formData = new FormData();
      let method = "post";
      let params = "";
      // finalise form data
      formData.append("form_id", router.currentRoute.params.formData.id);
      formData.append("data", JSON.stringify(sender.data));

      await myApi({
        url: "form_data" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            // ok

            // reset only if form succeful
            this.survey.clear();
            this.survey.render();
          } else {
            this.actionName = defaultActionText;
            console.log(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.errors,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          console.log(error.response.data.errors);
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.errors,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "form_data-view" });
    },
    goToNextItem() {
      router.push({ name: "form_data" });
    },
    goToList() {
      router.push({ name: "form_data" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-form";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, collectModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.$props.formData = this.$props.moduleData;
    };

    let { refFormObserver, getValidationState, resetForm } = formValidation(
      resetmoduleData
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};

function saveSurveyResults(url, json) {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify(json),
  })
    .then((response) => {
      if (response.ok) {
        // Handle success
      } else {
        // Handle error
      }
    })
    .catch((error) => {
      // Handle error
    });
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.sv_qstn {
  padding-bottom: 0.5em !important;
  padding: 0px;
  margin: 0px;
}
.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
./collectModule
